<template>
    <swiper :breakpoints="breakpoints" :modules="modules" :loop="true" :autoplay="autoplay" :scrollbar="scrollbar"
        navigation>
        <swiper-slide v-for="project in projects">
            <ProjectItem :project="project" />
        </swiper-slide>
    </swiper>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectItem from './ProjectItem.vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide, } from 'swiper/vue';
import { Navigation, Scrollbar, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
    components: {
        Swiper,
        SwiperSlide,
        ProjectItem
    },
    data() {
        return {
            scrollbar: {
                draggable: true
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            },
        }
    },
    computed: {
        ...mapGetters('project', {
            projects: 'getProjects'
        })
    },
    setup() {
        return {
            modules: [Navigation, Scrollbar, Autoplay],
        }
    }
}
</script>

<style lang="scss">
body {
    .swiper {
        padding-top: 50px;

        .swiper-button-next,
        .swiper-button-prev {
            top: 15px;

            &:after {
                font-size: 25px;
                color: #000201;
            }
        }

        .swiper-button-prev {
            right: 60px;
            left: auto;
        }
    }
}

body.dark {
    .swiper {

        .swiper-button-next,
        .swiper-button-prev {
            &:after {
                color: #FFFCF5;
            }
        }
    }
}

/* XS - Main */
@media (max-width: 575px) {
    body {
        .swiper {
            .swiper-button-prev {
                left: 43%;
                right: 0;
                transform: translateX(-50%);
            }

            .swiper-button-next {
                left: 57%;
                right: 0;
                transform: translateX(-50%);
            }
        }
    }
}
</style>