<template>
    <!-- Mobile menu -->
    <nav class="flex md:hidden">
        <!-- Close hamburger -->
        <div class="close-hamburger mt-2.5 space-y-1.5" @click="toggleNavbar">
            <span class="block h-0.5 w-8"></span>
            <span class="block h-0.5 w-8"></span>
            <span class="block h-0.5 w-8"></span>
        </div>
        <div :class="openClass">
            <!-- Open hamburger -->
            <div class="open-hamburger absolute top-0 left-0 px-5 py-4" @click="toggleNavbar">
                <svg class="h-10 w-10 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="#000201" stroke-width="1.5"
                    strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>
            <NavigationItems classes="flex flex-col items-center justify-between min-h-[250px]"
                @clicked-item="toggleNavbar" />
        </div>
    </nav>
</template>

<script>
// https://www.codementor.io/@giorgiasambrotta/hamburger-menu-with-react-and-tailwind-css-1qx6sruvua
import NavigationItems from './NavigationItems.vue';

export default {
    components: {
        NavigationItems,
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        openClass() {
            return this.open ? 'showMenuNav' : 'hideMenuNav'
        }
    },
    methods: {
        toggleNavbar() {
            this.open = !this.open
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

body {
    .hideMenuNav {
        display: none;
    }

    .showMenuNav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: white;
        z-index: 99;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-evenly);
        @include align-items(center);
    }

    .close-hamburger {
        span {
            background-color: #000201;
        }
    }

    .open-hamburger {
        svg {
            stroke: #000201;
        }
    }
}

body.dark {
    .showMenuNav {
        background: #000201;
    }

    .close-hamburger {
        span {
            background-color: #FFFCF5;
        }
    }

    .open-hamburger {
        svg {
            stroke: #FFFCF5;
        }
    }
}
</style>