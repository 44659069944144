<template>
    <transition name="modal">
        <div class="modal table w-full h-full" v-if="modal" @keydown.esc="closeModal">
            <a @click="closeModal" class="close-modal cursor-pointer">
                <img src="/img/icon/close.svg" alt="Close" class="close-modal__image">
            </a>
            <div class="modal__container w-full h-full">
                <div class="modal__body md:flex md:flex-row mx-auto block items-center flex-col h-full">
                    <div class="modal__image md:w-1/2 md:mt-0 mt-8">
                        <a :href="modal.url" target="_blank">
                            <img :src="modal.image" :alt="modal.name" />
                        </a>
                    </div>
                    <div class="modal__text md:pl-[40px] md:w-1/2 md:mt-0 md:text-left md:h-auto text-center mt-5 h-full">
                        <h2 class="modal__title">
                            <a :href="modal.url" target="_blank">{{ modal.name }}</a>
                        </h2>
                        <p class="modal__paragraph" v-html="modal.description"></p>
                        <a :href="modal.url" target="_blank" class="modal__link underline">
                            {{ modal.short_url }}
                        </a>
                        <p class="modal__tags" v-html="modal.technologies"></p>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// https://codesandbox.io/s/github/vuejs/v2.vuejs.org/tree/master/src/v2/examples/vue-20-modal-component
import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('modal', {
            modal: 'getModal'
        })
    },
    methods: {
        ...mapMutations('modal', [
            'TOGGLE_MODAL'
        ]),
        onEscapeCloseModal() {
            document.addEventListener('keyup', (event) => {
                if (event.keyCode === 27) {
                    this.TOGGLE_MODAL();
                }
            });
        },
        closeModal() {
            this.TOGGLE_MODAL()

            document.body.style.overflow = 'unset';
        }
    },
    created() {
        this.onEscapeCloseModal();
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/mixins";

.modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    @include transition(opacity 0.3s ease);

    &__title {
        margin: 0 0 20px 0;
    }

    &__paragraph,
    &__tags {
        font-family: SpaceGrotesk Regular;
    }

    &__tags {
        font-size: 16px;
        margin: 20px 0 0;
    }

    &__paragraph {
        font-size: 22px;
        margin: 0 0 10px 0;
    }

    &__link {
        font-family: SpaceGrotesk Bold;
        font-size: 22px;
    }

    &__container {
        background-color: #f8f4f0f2;
        @include transition(all 0.3s ease);
        box-sizing: border-box;
    }

    &__body {
        padding: 30px;
        max-width: 1220px;
        overflow: scroll;
    }

    &__image {}

    &__text {}
}

.close-modal {
    @include position-absolute($right: 17px, $top: 17px);

    &__image {
        width: 35px;
    }
}

/*
 * Transition
 */
.modal-enter-from {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter-to .modal-container {
    @include transform(scale(1.1));
}
</style>