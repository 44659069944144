import { createStore } from "vuex";
// modules
import modal from "./modal";
import project from "./project";

export default createStore({
    modules: {
        modal,
        project,
    }
});