<template>
    <!-- Desktop menu -->
    <nav class="hidden md:block w-1/3 pl-6">
        <NavigationItems />
    </nav>
</template>

<script>
import NavigationItems from './navigation/NavigationItems';

export default {
    components: {
        NavigationItems,
    },
}
</script>