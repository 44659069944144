<template>
    <a @click="openModal" class="link relative">
        <img :src="project.image" :alt="project.name">
        <div class="text z-10 flex items-center justify-center">
            <img src="/img/icon/link.svg" :alt="project.name" class="pr-2" />
            <h4>{{ project.name }}</h4>
        </div>
    </a>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: {
        project: {
            type: Object,
        },
    },
    methods: {
        ...mapMutations('modal', [
            'TOGGLE_MODAL'
        ]),
        openModal() {
            this.TOGGLE_MODAL(this.project)

            document.body.style.overflow = 'hidden';
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/mixins";

.link {
    cursor: pointer;

    &:hover {
        &:after {
            content: "";
            display: block;
            background-color: #000000c2;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .text {
            opacity: 1;
        }
    }

    .text {
        @include transition(0.3s);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;

        h4 {
            color: #fff;
            letter-spacing: -0.25px;
            font-size: 24px;
        }
    }
}
</style>