<template>
    <header class="pl-6 pr-6 pt-4 pb-[70px] flex justify-between">
        <ResponsiveNavigation />
        <router-link to="/">MilanNavrátil.</router-link>
        <div class="mode flex items-center">
            <img :src="image" alt="Switch mode" @click="toggleMode" />
        </div>
    </header>
</template>

<script>
import NavigationItems from './navigation/NavigationItems.vue';
import ResponsiveNavigation from './navigation/ResponsiveNavigation.vue';

export default {
    components: {
        NavigationItems,
        ResponsiveNavigation
    },
    data() {
        return {
            dark: false
        }
    },
    computed: {
        image() {
            return this.dark
                ? "/img/icon/w-sun.svg"
                : "/img/icon/sun.svg"
        }
    },
    methods: {
        toggleMode() {
            let element = document.body;
            element.classList.toggle("dark");

            this.dark = !this.dark
        }
    },
}
</script>

<style lang="scss" scoped>
a {
    font-family: Archivo Bold;
    letter-spacing: -0.8px;
    font-size: 24px;
}

.mode img {
    cursor: pointer;
}
</style>