<template>
    <ul :class="classes">
        <li class="pb-3">
            <router-link to="/" @click="clickedItem">o mne</router-link>
        </li>
        <li class="pb-3">
            <router-link to="/projects" @click="clickedItem">portfólio</router-link>
        </li>
        <li class="pb-3">
            <router-link to="/resume" @click="clickedItem">cv</router-link>
        </li>
        <li class="pb-3">
            <router-link to="/contact" @click="clickedItem">kontakt</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        classes: {
            type: String,
        },
    },
    methods: {
        clickedItem() {
            this.$emit('clicked-item')
        }
    },
}
</script>

<style lang="scss" scoped>
body {
    nav {
        ul {
            li {
                a {
                    font-size: 24px;
                    font-family: Archivo Medium;
                    color: #00020166;

                    &.router-link-exact-active {
                        color: #000201;
                    }
                }
            }
        }
    }
}

body.dark {
    nav {
        ul {
            li {
                a {
                    color: rgba(255, 252, 245, 0.3);

                    &.router-link-exact-active {
                        color: #fffcf5cc;
                    }
                }
            }
        }
    }
}

/* XS - Main */
@media (max-width: 575px) {
    body {
        nav {
            ul {
                li {
                    a {
                        font-size: 45px;
                    }
                }
            }
        }
    }
}
</style>