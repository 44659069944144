<template>
  <Header />
  <main class="container flex">
    <router-view v-slot="{ Component }">
      <Sidebar />
      <section class="md:pl-4 md:w-2/3 w-full">
        <transition name="scale" mode="out-in">
          <component :is="Component" />
        </transition>
      </section>
    </router-view>
  </main>
  <Modal />
</template>

<script>
import Sidebar from "./partials/Sidebar.vue";
import Header from "./partials/Header.vue";
import Modal from "./partials/Modal.vue";

export default {
  components: {
    Sidebar,
    Header,
    Modal
  },
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
