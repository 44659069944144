const modal = {
    namespaced: true,
    state: () => ({
        modal: undefined
    }),
    mutations: {
        TOGGLE_MODAL(state, payload) {
            if (payload === undefined) {
                state.modal = undefined
            } else {
                state.modal = payload
            }

        }
    },
    getters: {
        getModal: (state) => state.modal
    }
}

export default modal