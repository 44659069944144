const project = {
  namespaced: true,
  state: () => ({
    projects: [
      {
        tags: "all,eshop",
        name: "Glassery",
        short_url: "www.glassery.sk",
        url: "https://glassery.sk/",
        image: "/img/project/glassery.png",
        description:
          'Glassery ponúka zákazníkom rozhranie pre objednávku plakiet s motívmi Spotify, Instagram, zamilované a iné.<br>Plaketa môže slúžiť ako spomienka alebo darček pre blízkeho.<br>Glassery poskytuje SK a CZ jazykové mutácie s administračným rozhraním "na mieru".',
        technologies: "#VueJS #VUEX #Laravel #SCSS #API",
      },
      {
        tags: "all,apps",
        name: "zaPOIsa",
        short_url: "www.zapoisa.sk",
        url: "https://zapoisa.sk/",
        image: "/img/project/zapoisa.png",
        description:
          "ZaPOISA je iniciatíva zameraná na podporu spolupráce, s cieľom skvalitnenia prostredia v obciach a regiónoch.<br>Platforma zaPOIsa umožňuje generovať propagačné materiály (plagáty, fb post & cover) organizátorom udalostí.",
        technologies: "#VueJS #VUEX #Laravel #SCSS #API #LaravelNova",
      },
      {
        tags: "all,apps",
        name: "VisualBack",
        short_url: "www.visualback.com",
        url: "https://visualback.com/",
        image: "/img/project/visualback.png",
        description:
          "VisualBack chce pomôcť začínajúcim webovým vývojárom a ponúknuť im prostredie pre získanie spätnej väzby na dizajn ich webu.<br>Feedback (komentovanie) je realizovaný prostredníctvom pinov na webe, ku ktorému je možné priložiť prílohu či navrhovaný CSS kód.<br>Myšlienku VB sme so spolužiakom rozvíjali najmä počas vysokoškolského štúdia.",
        technologies: "#VueJS #VUEX #Laravel #SCSS #API",
      },
      {
        tags: "all,presentation",
        name: "Imperioom escape room",
        short_url: "www.imperioom.sk",
        url: "https://imperioom.sk/",
        image: "/img/project/imperioom.png",
        description:
          "Imperioom otvára jediným escape room-om v okolí Trnavy. Primárnym účelom webstránky je informovať návštevníkov o dostupných roomkach a ponúknuť voľné termíny pre rezerváciu.<br> Projekt taktiež zahŕňa administračné rozhranie pre správu rezervácií, hier a ďalšie užitočné funkcionality vyšpecifikované práve pre escape room.",
        technologies: "#VueJS #Pinia #API #Laravel #Livewire #WebSocket",
      },
    ],
  }),
  getters: {
    getProjects: (state) => state.projects,
  },
};

export default project;
