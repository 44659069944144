<template>
  <div class="about">
    <div class="text md:pr-6 px-6 pb-8">
      <h1 class="pb-6 md:text-left text-center">
        Jednoducho milujem to,<br />čo robím.
      </h1>
      <h3 class="pb-6 md:text-left text-center">Alebo inak... Programujem!</h3>
      <p class="pb-6 md:text-left text-center">
        Volám sa <strong>Milan Navrátil</strong> a som
        <strong>full stack web developer</strong>. V čase, keď toto čítaš, som
        už určite v Košiciach, ale pôvodom som hrdý Seredčan
        <span class="emoji">👍</span>. Bavia ma športy (futbal, stolný tenis) a
        snažím sa zostávať vždy pozitívny. <br />
        Mojím cieľom je <span class="emoji">👣</span> vpred, profesijne aj
        ľudsky.
      </p>
      <p class="pb-6 md:text-left text-center">
        Vývoju web stránok sa venujem už viac než <strong>5 rokov</strong>.
        Počas tohto obdobia som robil na vývoji jednoduchých <strong>prezentačných stránok</strong>,
        <strong>eshopoch</strong>, <strong>portáloch</strong> až po <strong>aplikácie na mieru</strong> a <strong>interné systémy</strong>. Hlavnými nástrojmi
        môjho stacku sú <strong>Laravel</strong> a <strong>VueJS</strong>. Nie
        sú mi cudzie ani <strong>Wordpress</strong>, <strong>SCSS</strong>,
        <strong>Bootstrap</strong> či <strong>Tailwind</strong>. Dávnejšie som
        už nakukol aj do <strong>ReactJS</strong> a
        <strong>React Native</strong>. Samozrejme, všetko musí byť na
        <strong>Gite</strong>! <span class="emoji">🤟</span>
      </p>
      <p class="md:text-left text-center">
        Ak som ťa neodradil a chceš vedieť viac, pozri si moje
        <router-link to="/projects">portfólio</router-link>.
      </p>
    </div>
    <Carousel />
  </div>
</template>

<script>
import Carousel from "../partials/Carousel.vue";

export default {
  components: {
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
.about {
  h3 {
    color: #d3902a;
    font-family: SpaceGrotesk Bold;
    font-size: 24px;
  }

  a,
  strong {
    font-family: SpaceGrotesk Bold;
  }

  a {
    text-decoration: underline;
  }
}
</style>
